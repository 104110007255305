import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Alert } from './components/Alert';
import { YouTube } from './components/YouTube';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "create-events-brochure"
    }}>{`Create Events Brochure`}</h1>
    <p>{`Staff and members can create their very own customised events brochure in the web browser. It's possible to define event locations, categories and a time period, and a PDF brochure will be automatically generated for download. This can be useful for branches that would like to create a brochure specifically for use at their location, or one catered to the interests of a specific demographic.`}</p>
    <Alert kind="warning" mdxType="Alert">The number of events included in the brochure may be limited by the system to avoid large PDFs crashing members' web browsers.</Alert>
    <h2 {...{
      "id": "create-events-brochure-1"
    }}>{`Create Events Brochure`}</h2>
    <ol>
      <li parentName="ol">{`Visit your calendar's homepage`}</li>
      <li parentName="ol">{`Scroll down to the footer area and click 'Create Brochure'`}</li>
      <li parentName="ol">{`Define your event locations, categories and required time period`}</li>
      <li parentName="ol">{`Click 'Create Brochure'`}</li>
      <li parentName="ol">{`It may take up to 30s for the brochure to be generated and the download to commence`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      